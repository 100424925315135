<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="현장경영 스케줄 현황">
            <div class="btn-ibbox title-btnbox ml-190 mb-10">
                <router-link :to="{ name:'customerManagement-scheduleVisitWeekly' }"><button class="btn-default type2">현장경영 스케줄</button></router-link>
            </div>
          - 통계 기준 : 마케팅 미팅 수치 / 고객관리현황(컨택/제안/PT/입찰) 수치 현황이 출력됩니다.<br>
          - 검색 시 ‘리드만 검색’ 체크하는 경우 검색조건에 맞는 팀장 이상 직급 소유자의 통계만 확인할 수 있습니다
        </CarrotTitle>
        <div class="board">

          <div class="mt-50 mb-20">

            <table class="table-row table-serach">
              <tbody>
              <tr>
                <th width="180">기간</th>
                <td>
                  <CarrotDatePicker
                      class="inline-datepicker"
                      v-model="bbs.sdate"
                  ></CarrotDatePicker>
                  ~
                  <CarrotDatePicker
                      class="inline-datepicker"
                      v-model="bbs.edate"
                  ></CarrotDatePicker>
                </td>

              </tr>

              <tr>
                <th width="180">검색조건</th>
                <td>
                  <CarrotDept  v-model="bbs.office" class="w-200px"></CarrotDept>
                  <CarrotTeam  :idx_office="bbs.office" v-model="bbs.team" class="w-200px ml-5"></CarrotTeam>
                  <CarrotStaff :idx_office="bbs.office" :idx_team="bbs.team" v-model="bbs.staff" class="w-200px"></CarrotStaff>

                  리드여부
                  <input type="checkbox" v-model="bbs.isLead">

                </td>
              </tr>

              </tbody>
            </table>
            <button
                class="btn-default float-right mb-20"
                @click="bbs.doSearchAll()"
            >
              검색
            </button>


            <!-- @change="bbs.doSelect" -->
          </div>

            <div class="mt-40">
                <div v-if="bbs.isLoaded==true">
                  <table class="table-col" style="max-width:100%" v-for="(irow, chunkIndex) in bbs.newList" :key="chunkIndex"  :style="{ 'width':(150*irow.length)+'px' }">
                    <colgroup>
                      <col v-for="i in irow.length" :key="i" style="width:150px">
                    </colgroup>
                    <thead>
                    <tr>
                      <th v-for="(row, i) in irow" :key="i">{{ row.team_name }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td v-for="(row, i) in irow" :key="i">{{ row.cnt_schedule }} / {{ row.cnt_visited }}</td>
                    </tr>
                    </tbody>
                  </table>

                    <table class="table-col mt-20">
                        <colgroup>
                            <col width="100">
                            <col width="100">
                            <col width="120">

                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">
                            <col width="50">

                            <col width="80">
                            <col width="80">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th rowspan="2">본부</th>
                                <th rowspan="2">팀</th>
                                <th rowspan="2">이름</th>
                                <th colspan="13">방문 스케줄 등록 수</th>
                                <th rowspan="2"  style="border-left:1px solid #ddd">고객사일지<br>작성 수</th>
                                <th rowspan="2">외근 고객사</th>
                            </tr>
                            <tr>
                                <th>전체</th>
                              <th v-for="(irow, i) in bbs.rtype_list" :key="i" class="w-50px" ><span >{{ irow.name }}</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.office_name }}</td>
                                <td>{{ irow.team_name }}</td>
                                <td>{{ irow.ename }}({{ irow.kname }})</td>
                                <td>{{ irow.tot_schedule }}</td>

                              <td v-for="(jrow, j) in bbs.rtype_list" :key="j">
                                <template v-for="(krow, k) in irow.schedules" :key="k">
                              <span v-if="jrow.code == krow.rtype">
                                <p
                                    v-if="krow.rtype == '마케팅 미팅'"
                                    :style="{
                                    color: krow.cnt_schedule <= 2 ? '#ff0000' : krow.cnt_schedule >= 5 ? '#00b050' : 'inherit',
                                  }"
                                >
                                  {{ krow.cnt_schedule }}
                                </p>
                                <p v-else>
                                  {{ krow.cnt_schedule }}
                                </p>
                              </span>
                                </template>
                              </td>
                              <td :style="{ color: irow.schedules.some(schedule => schedule.rtype == '마케팅 미팅' && schedule.cnt_schedule > irow.cnt_visited) ? 'red' : '' }">
                                {{ irow.cnt_visited }}
                              </td>
                                <td>{{ irow.companyname }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td :colspan="6+bbs.rtype_list.length">본부/팀을 선택하세요.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from '@vue/runtime-core';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import CarrotStaff from "@/components/common/CarrotStaff.vue";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
export default {
    layout:"customerManagement",
    components: {
        CarrotDept,
        CarrotTeam,
        CarrotStaff,
        CarrotDatePicker
    },
    setup() {
        const toast = useToast()

        const bbs = reactive({
            rtype_list : [],
            isLoaded   : true,

            isLead:false,
            tdate   : "",
            attrs   : [
                { key:'selected', highlight:true, dates:[ new Date() ] },
            ],

            temp_cnt:0,
            weeks   : [],

            sdate   : "",             // 
            edate   : "",             // 
            office  : "0",            // hq office
            team    : "0",            // hq team
            staff    : "0",            // hq staff

            list  : [], total  : 0,
            alist : [], atotal : 0,
            newList :[],
            dateFormat : (d) => {
                return [d.getFullYear(), d.getMonth()>8?d.getMonth()+1:'0'+(d.getMonth()+1), d.getDate()>9?d.getDate():'0'+d.getDate()].join('-');
            },

            doSearchRtype : () => {
                axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.rtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSelect : () => {
                let nd = new Date(bbs.tdate);
                let td = nd.getDate() - nd.getDay();
                let fd = new Date(nd.setDate(td));
                let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+6);
                bbs.sdate = [fd.getFullYear(), fd.getMonth()>8?fd.getMonth()+1:'0'+(fd.getMonth()+1), fd.getDate()>9?fd.getDate():'0'+fd.getDate()].join('-');
                bbs.edate = [ld.getFullYear(), ld.getMonth()>8?ld.getMonth()+1:'0'+(ld.getMonth()+1), ld.getDate()>9?ld.getDate():'0'+ld.getDate()].join('-');

                for(let i=0; i<7; i++){
                    bbs.attrs[0].dates[i] = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+i);
                    bbs.weeks[i] = bbs.dateFormat(bbs.attrs[0].dates[i]);
                }
                bbs.doSearch();
                bbs.doSearchAnalysis();
            },

            doPrev : () => {
                let nd = new Date(bbs.sdate);
                bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-7);
                bbs.doSelect();
            },

            doNext : () => {
                let nd = new Date(bbs.sdate);
                bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()+7);
                bbs.doSelect();
            },


            doSearchAll : () => {
                bbs.doSearchAnalysis();
                bbs.doSearch();
            },

            doSearchAnalysis : () => {
                let params = {
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    office : bbs.office,
                    team   : bbs.team,
                    staff   : bbs.staff,
                    isLead : bbs.isLead ? "Y" :"N"
                };

                axios.get("/rest/customermgr/getScheduleAnalysis", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.alist  = res.data.list;
                        bbs.newList  = res.data.newList;
                        bbs.atotal = res.data.total;

                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {

                let params = {
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    office : bbs.office,
                    team   : bbs.team,
                    staff  : bbs.staff,
                    isLead : bbs.isLead ? "Y" :"N"
                };

                axios.get("/rest/customermgr/getScheduleStatus", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;


                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }

                    sessionStorage.removeItem('scheduleVisitStatus');
                });
            }
        });


        onMounted(() => {
            // Mounted
            let nd = new Date();
            bbs.tdate = bbs.dateFormat(nd);

            bbs.doSelect();
            bbs.doSearchRtype();

        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
.btn-weeks {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; opacity: 0.001; z-index: 1;
}
</style>